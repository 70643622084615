import { useEffect, useState } from "react";
import { formatDate, InitState, useWebSocket, UUID } from "gx-npm-lib";
import * as singleSpa from "single-spa";
import { useWorkspaceHeaderContext } from "./context/workspace-header.context";
import { WebSocketMessageEvents, WorkspaceHeaderPayloadType } from "./lib/types";
import NavigationDialog from "./sections/navigation-dialog/navigation-dialog.component";
import { handleLoadWorkspaceHeaderData } from "./context/load/use-load-workspace-header.hook";
import { loadWorkspaceHeaderData } from "./context/load/workspace-header-load.lib";

type NavEventType = {
  event: WebSocketMessageEvents;
  isOpen: boolean;
  url: string;
  username: string;
};

type WsInMsg = {
  event: WebSocketMessageEvents;
};
type WsInMsgAccessRevoked = WsInMsg & {
  name: string;
};
type WsInMsgProductRemovedFromEval = WsInMsg & {
  name: string;
};
type WsInMsgUpdateInitiativeState = WsInMsg & {
  state: InitState;
};
type WsInMsgViewingInitiativeType = WsInMsg & {
  userList: string[];
};

type WsOutMsgViewingInitiative = {
  action: "message";
  data: {
    event: WebSocketMessageEvents;
    initiativeId: UUID;
    isViewing: boolean;
  };
};

const viewingInitiativeMessage = (initId = "" as UUID, isViewing = false): WsOutMsgViewingInitiative => {
  return {
    action: "message",
    data: { event: WebSocketMessageEvents.VIEWING_INITIATIVE, initiativeId: initId, isViewing },
  };
};

const AppWebSocket = () => {
  const [wsMessage, wsPublish] = useWebSocket<
    WsInMsgAccessRevoked | WsInMsgProductRemovedFromEval | WsInMsgUpdateInitiativeState | WsInMsgViewingInitiativeType,
    WsOutMsgViewingInitiative
  >();
  const workspaceHeaderContext = useWorkspaceHeaderContext();
  const { initId, initProdId, setActiveViewers, setInitState, setInitStateUpdateDate } = workspaceHeaderContext;
  const [navEvent, setNavEvent] = useState<NavEventType>({
    event: WebSocketMessageEvents.NONE,
    isOpen: false,
    url: "",
    username: "",
  });

  useEffect(() => {
    if (initId) {
      wsPublish(viewingInitiativeMessage(initId, true));
    }
    return () => {
      if (initId) {
        wsPublish(viewingInitiativeMessage(initId, false));
      }
    };
  }, [initId, wsPublish]);

  useEffect(() => {
    if (!wsMessage) {
      return;
    }

    switch (wsMessage.event) {
      case WebSocketMessageEvents.INITIATIVE_ACCESS_REVOKED:
      case WebSocketMessageEvents.INITIATIVE_DELETED: {
        const wsEvent = wsMessage as WsInMsgAccessRevoked;
        const url = "/s/evaluations";
        setNavEvent({
          event: wsEvent.event,
          isOpen: true,
          url,
          username: wsEvent.name,
        });
        break;
      }
      case WebSocketMessageEvents.PRODUCT_REMOVED_FROM_EVAL:
        {
          const wsEvent = wsMessage as WsInMsgProductRemovedFromEval;
          const url = `/s/evaluation/${initId}/scorecard`;
          setNavEvent({
            event: wsEvent.event,
            isOpen: true,
            url,
            username: wsEvent.name,
          });
        }
        break;
      case WebSocketMessageEvents.UPDATE_INITIATIVE_STATE: {
        const wsEvent = wsMessage as WsInMsgUpdateInitiativeState;
        const date = formatDate(new Date().toISOString(), "YYYY-MM-DD");
        setInitState(wsEvent.state);
        setInitStateUpdateDate(date);
        break;
      }
      case WebSocketMessageEvents.UPDATE_PRODUCT_STATE:
        loadWorkspaceHeaderData(
          { initId, initProdId },
          (data: WorkspaceHeaderPayloadType) => {
            handleLoadWorkspaceHeaderData(data, workspaceHeaderContext);
          },
          () => {}
        );
        break;
      case WebSocketMessageEvents.VIEWING_INITIATIVE: {
        const wsEvent = wsMessage as WsInMsgViewingInitiativeType;
        if (Array.isArray(wsEvent.userList)) {
          setActiveViewers(wsEvent.userList.sort());
        }
        break;
      }
    }
  }, [initId, initProdId, setActiveViewers, setInitState, setInitStateUpdateDate, workspaceHeaderContext, wsMessage]);

  return (
    <NavigationDialog
      handleConfirm={() => singleSpa.navigateToUrl(navEvent.url)}
      isOpen={navEvent.isOpen}
      userName={navEvent.username}
      wsMessageEvent={navEvent.event}
    />
  );
};

export default AppWebSocket;
