import { Dispatch, SetStateAction } from "react";
import { InitProdState, InitState, InitUserRole, UUID } from "gx-npm-lib";

export enum TabNames {
  NONE = "",
  PROFILE = "Profile",
  MY_SCORESHEET = "My Scoresheet",
  RESPONSES = "Responses",
  DOCUMENTS = "Documents",
}

export enum NewAppPaths {
  PRODUCT_PROFILE_TAB = "/profile",
  PRODUCT_SCORESHEET_TAB = "/scoresheet",
  PRODUCT_RESPONSES_TAB = "/responses",
  PRODUCT_DOCUMENTS_TAB = "/documents",
}

/**
 * @deprecated
 */
export enum AppPaths {
  PRODUCT_PROFILE_TAB = "/s/product-profile",
  PRODUCT_SCORESHEET_TAB = "/s/product-scoresheet",
  PRODUCT_RESPONSES_TAB = "/s/product-responses",
  PRODUCT_DOCUMENTS_TAB = "/s/product-documents",
}

export enum AvailableActions {
  ADD_PRODUCT_TO_EVAL = "ADD_PRODUCT_TO_EVAL",
  CHOOSE_PRODUCT = "CHOOSE_PRODUCT",
  REMOVE_PRODUCT_FROM_EVAL = "REMOVE_PRODUCT_FROM_EVAL",
  SCREEN_OUT_PRODUCT = "SCREEN_OUT_PRODUCT",
  REVERT_AWARD = "REVERT_AWARD",
}

export enum CustomEvents {
  SCORESHEET_SAVING_COUNT = "SCORESHEET_SAVING_COUNT",
  WORKSPACE_HEADER_ACTIONS = "WORKSPACE_HEADER_ACTIONS",
}

export enum InitStatus {
  PLANNING = "PLANNING",
  EVAL_STARTED = "EVAL_STARTED",
  SELECTED = "SELECTED",
}

export enum WebSocketMessageEvents {
  INITIATIVE_ACCESS_REVOKED = "INITIATIVE_ACCESS_REVOKED",
  INITIATIVE_DELETED = "INITIATIVE_DELETED",
  NONE = "NONE",
  PRODUCT_REMOVED_FROM_EVAL = "PRODUCT_REMOVED_FROM_EVAL",
  UPDATE_INITIATIVE_STATE = "UPDATE_INITIATIVE_STATE",
  UPDATE_PRODUCT_STATE = "UPDATE_PRODUCT_STATE",
  VIEWING_INITIATIVE = "VIEWING_INITIATIVE",
}

export type WorkspaceHeaderContextValue = {
  selectedTab: TabNames | string;
  isLegalAlertShown: boolean;
  isSurveyLegalDismissed: boolean;
  hasRequestedDocuments: boolean;
  isSurveySubmitted: boolean;
  activeViewers: string[];
  awardedProductImageLoc: string;
  awardedProductName: string;
  initId: UUID;
  initName: string;
  initProdId: UUID;
  initState: InitState;
  initStateUpdateDate: string;
  initStatus: InitStatus;
  productName: string;
  productImageLoc: string;
  productState: InitProdState;
  savingCount: number;
  sourcePage: string;
  surveyId: UUID;
  userRole: InitUserRole | "";
  setSelectedTab: Dispatch<SetStateAction<TabNames | "">>;
  setIsLegalAlertShown: Dispatch<SetStateAction<boolean>>;
  setHasRequestedDocuments: Dispatch<SetStateAction<boolean>>;
  setIsSurveySubmitted: Dispatch<SetStateAction<boolean>>;
  setIsSurveyLegalDismissed: Dispatch<SetStateAction<boolean>>;
  setActiveViewers: Dispatch<SetStateAction<string[]>>;
  setAwardedProductImageLoc: Dispatch<SetStateAction<string>>;
  setAwardedProductName: Dispatch<SetStateAction<string>>;
  setInitId: Dispatch<SetStateAction<UUID>>;
  setInitName: Dispatch<SetStateAction<string>>;
  setInitProdId: Dispatch<SetStateAction<UUID>>;
  setInitState: Dispatch<SetStateAction<InitState>>;
  setInitStateUpdateDate: Dispatch<SetStateAction<string>>;
  setInitStatus: Dispatch<SetStateAction<InitStatus>>;
  setProductName: Dispatch<SetStateAction<string>>;
  setProductImageLoc: Dispatch<SetStateAction<string>>;
  setProductState: Dispatch<SetStateAction<InitProdState>>;
  setSavingCount: Dispatch<SetStateAction<number>>;
  setSourcePage: Dispatch<SetStateAction<string>>;
  setSurveyId: Dispatch<SetStateAction<UUID>>;
  setUserRole: Dispatch<SetStateAction<InitUserRole | "">>;
};

export type AwardedProductType = {
  imageLoc: string;
  name: string;
};

export type WorkspaceHeaderPayloadType = {
  awardedProduct: AwardedProductType;
  initName: string;
  initState: InitState;
  initStateUpdateDate: string;
  initStatus: InitStatus;
  productImageLoc: string;
  productName: string;
  productState: InitProdState;
  surveyId: UUID | "";
  hasRequestedDocuments: boolean;
  isSurveySubmitted: boolean;
  isSurveyLegalDismissed: boolean;
};

export type WorkspaceHeaderConfig = {
  initId: UUID;
  initProdId: UUID;
};
