import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { SelectedVendorIcon } from "gx-npm-icons";
import { InitProdState, InitUserRole } from "gx-npm-lib";
import { PopoverMenu, TooltipV2, useFeatureFlag } from "gx-npm-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useWorkspaceHeaderContext } from "../../../../context/workspace-header.context";
import { AvailableActions, InitStatus } from "../../../../lib/types";
import styles from "./product-state-actions-popover-menu.styles";
import { GCOM_3695_ctaButtonColorUpdate } from "../../../../lib/feature-flags";

const useStyles = makeStyles(() => styles);
const ProductStateActionsPopoverMenu = ({ onClickMenu }: { onClickMenu?: (type: string) => void }) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const { t } = useTranslation();
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const { initStatus, productState, userRole } = useWorkspaceHeaderContext();

  let noCurrentSelectionText = "";
  let menuItems: Array<{ index: number; name: string; type?: AvailableActions }> = [];
  const isViewerOrContributor = userRole === InitUserRole.VIEWER || userRole === InitUserRole.CONTRIBUTOR;
  if (productState === InitProdState.AWARDED) {
    noCurrentSelectionText = t("Selected vendor");
    menuItems = [
      {
        index: 0,
        name: t("Revert vendor selection"),
        type: AvailableActions.REVERT_AWARD,
      },
    ];
  }

  if (productState === InitProdState.IN_EVALUATION) {
    noCurrentSelectionText = t("In evaluation");
    menuItems = [
      {
        index: 0,
        name: t("Screen out vendor"),
        type: AvailableActions.SCREEN_OUT_PRODUCT,
      },
    ];
    if (initStatus !== InitStatus.PLANNING) {
      menuItems.push({
        index: 1,
        name: t("Choose this vendor"),
        type: AvailableActions.CHOOSE_PRODUCT,
      });
    }
  }

  if (productState === InitProdState.SCREENED_OUT) {
    noCurrentSelectionText = t("Screened out");
    menuItems = [
      {
        index: 0,
        name: t("Add back to eval"),
        type: AvailableActions.ADD_PRODUCT_TO_EVAL,
      },
    ];
  }

  const handleMenuClick = (menuIndex: number) => {
    const type = menuItems.find(({ index }) => index === menuIndex)?.type;
    if (type && onClickMenu) {
      onClickMenu(type);
    }
  };

  const handleOpen = (isMenuOpen: boolean) => {
    setIsOpen(isMenuOpen);
  };

  const getTooltipContent = () => {
    if (isViewerOrContributor) {
      return t("This action is only available to evaluation owners.");
    }
    if (!isOpen && productState === InitProdState.AWARDED) {
      return t("You may unselect this vendor and/or choose another vendor at any time.");
    }
    return "";
  };
  return (
    <div className={classNames(classes.root, isFFGCOM3695 && classes.rootGCOM3695)}>
      {productState === InitProdState.AWARDED && (
        <span className={classes.selectedVendorIcon}>
          <SelectedVendorIcon title={"selected vendor icon"} />
        </span>
      )}
      <TooltipV2
        title={getTooltipContent()}
        rootClassName={"gx-workspace-header-awarded-tool-tip"}
        placement="bottom"
        PopperProps={{ modifiers: { offset: { offset: "0px, 0px" } } }}
      >
        <div>
          <PopoverMenu
            alignmentType="rightBottom"
            disabled={isViewerOrContributor}
            iconType="arrowDynamic"
            handleOpenStatus={handleOpen}
            menuItems={menuItems}
            noCurrentSelectionText={noCurrentSelectionText}
            onClick={handleMenuClick}
            rootClassName={classNames(
              classes.popoverMenu,
              productState === InitProdState.AWARDED && "awarded",
              productState === InitProdState.IN_EVALUATION && "ineval",
              productState === InitProdState.SCREENED_OUT && "screened-out"
            )}
            variant="select"
          />
        </div>
      </TooltipV2>
    </div>
  );
};

export default ProductStateActionsPopoverMenu;
