import { colorPalette, weightPalette } from "gx-npm-common-styles";
import { productHeaderHeight, productHeaderHeightScrolled } from "../../lib/header-styles";

const colorCarbon = colorPalette.neutrals.carbon.hex;
const colorCoal = colorPalette.neutrals.coal.hex;
const colorStone = colorPalette.neutrals.stone.hex;

const weightAmtMedium = weightPalette.medium.amount;
const weightVarMedium = weightPalette.medium.variation;

const productHeaderStyles = {
  awardedProductLogo: {},
  awardedProductName: {},
  currentAwardedProduct: {},
  meta: {
    alignItems: "center",
    animationDuration: "0.5s",
    animationFillMode: "forwards",
    animationName: "$fadeIn",
    display: "flex" as string as "flex",
  },
  pipe: {
    borderRight: `1px ${colorStone} solid`,
    height: 24,
    marginLeft: 24,
    marginRight: 24,
    opacity: 0.6,
  },
  status: {
    color: colorCoal,
    fontVariationSettings: weightVarMedium,
    fontWeight: weightAmtMedium,
    width: "max-content",
  },
  statusGCOM3606: {
    width: "max-content",
  },
  vendorHeader: {
    alignItems: "center",
    backgroundColor: "#ffffff",
    color: colorCarbon,
    display: "flex",
    height: `${productHeaderHeight}px`,
    justifyContent: "space-between",
    paddingBottom: 24,
    paddingTop: 24,
    position: "relative" as string as "relative",
    transition: "all 300ms ease-in-out, box-shadow 0.5s ease-in-out",
    "&.isScreenedOut img": {
      filter: "grayscale(100%)",
    },
    zIndex: 1104,
    "&.scrolling": {
      height: `${productHeaderHeightScrolled}px`,
      "& $vendorIconAwarded": {
        animationDuration: "0.5s",
        animationFillMode: "forwards",
        animationName: "$fadeIn",
      },
      "& $vendorName": {
        fontSize: "16px",
      },
    },
    "&.scrolling.awarded": {
      paddingLeft: "40px",
    },
    "& $vendorActionsContainer": {
      display: "flex" as string as "flex",
      maxWidth: 368,
      transition: "max-width 0.5s ease-in-out",
      "&.scrolling": {
        animationDuration: "0.5s",
        animationFillMode: "forwards",
        animationName: "$fadeOut",
        maxWidth: 0,
      },
    },
    "& $vendorActionsAdded": {
      display: "flex" as string as "flex",
    },
    "& $vendorHeaderLoader": {
      position: "relative" as string as "relative",
      width: "100%",
    },
    "& $currentAwardedProduct": {
      alignItems: "center",
      display: "flex",
      width: "max-content",
      "& $awardedProductLogo": {
        paddingLeft: 13,
        paddingRight: 13,
      },
      "& $awardedProductName": {
        color: colorCoal,
      },
    },
  },
  vendorName: {
    marginLeft: "16px",
  },
  vendorBrand: {
    display: "flex",
    alignItems: "center",
  },
  vendorActions: {},
  vendorActionsAdded: {
    "& .actionFadeIn": {
      animationFillMode: "both",
      animationDuration: "0.5s",
      animationName: "$fadeIn",
      animationDelay: "300ms",
    },
  },
  vendorActionsContainer: {},
  vendorHeaderLoader: {},
  vendorIconAwarded: {
    left: 0,
    opacity: 0,
    position: "absolute" as string as "absolute",
    top: -20,
    width: 0,
    "& svg": {
      width: 30,
    },
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  "@keyframes fadeOut": {
    "0%": { opacity: 1 },
    "100%": { opacity: 0 },
  },
};

export default productHeaderStyles;
