import { makeStyles } from "@material-ui/core";
import { handleEvent } from "gx-npm-lib";
import {
  Button,
  ButtonLoader,
  Dialog,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./action-dialog.styles";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

type DialogLabelsType = {
  title: string;
  body1: { start: string; end: string };
  body2: string;
  buttons: { cancel: string; confirm: string };
};

type ActionDialogProps = {
  isClickScrimClosing?: boolean;
  isLoading?: boolean;
  isOpen?: boolean;
  labels: DialogLabelsType;
  onClose?: () => void;
  onConfirm?: () => void;
  name?: string;
};

const useStyles = makeStyles(() => styles);

const ActionDialog = ({
  isClickScrimClosing = true,
  isLoading = false,
  isOpen = false,
  labels,
  onClose,
  onConfirm,
  name = "",
}: ActionDialogProps) => {
  const handleClose = () => {
    if (isClickScrimClosing) {
      handleEvent(onClose);
    }
  };

  const classes = useStyles();
  const { title, body1, body2, buttons } = labels;

  return (
    <Dialog
      open={isOpen}
      handleClose={handleClose}
      title={title}
      body={
        <div className={classes.body}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <div className={classes.bodyFirst}>
                <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
                  {body1.start}
                </TypographyComponent>
                <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"medium"}>
                  {` ${name} `}
                </TypographyComponent>
                <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
                  {body1.end}
                </TypographyComponent>
              </div>
              <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
                {body2}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p className={classes.bodyFirst}>
                <span>{body1.start}</span>
                <span className="semi-bold">{` ${name} `}</span>
                <span>{body1.end}</span>
              </p>
              <p>{body2}</p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      }
      footer={
        <div className={classes.footer}>
          {!!onClose && (
            <Button onClick={() => handleEvent(onClose)} rootClassName="btn-tertiary">
              {buttons.cancel}
            </Button>
          )}
          {!!onConfirm && (
            <div className={classes.confirm}>
              <ButtonLoader
                btnClass="primary-destructive-btn"
                handleButtonClick={() => handleEvent(onConfirm)}
                isLoading={isLoading}
              >
                {buttons.confirm}
              </ButtonLoader>
            </div>
          )}
        </div>
      }
      rootClassName={classes.root}
    />
  );
};

export default ActionDialog;
