import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { InitUserRole } from "gx-npm-lib";
import { PopoverMenu, TooltipV2 } from "gx-npm-ui";
import { ProductOptionsType } from "./product-options-popover-menu.types";
import { useWorkspaceHeaderContext } from "../../../../context/workspace-header.context";

const VendorOptionsPopoverMenu = ({ onClickMenu }: { onClickMenu?: (type: string) => void }) => {
  const { t } = useTranslation();
  const { userRole } = useWorkspaceHeaderContext();

  const menuItems = [{ index: 0, name: t("Delete from evaluation"), type: ProductOptionsType.DELETE }];

  const handleMenuClick = (menuIndex: number) => {
    const type = menuItems.find(({ index }) => index === menuIndex)?.type;
    if (type && onClickMenu) {
      onClickMenu(type);
    }
  };
  const isViewerOrContributor = userRole === InitUserRole.VIEWER || userRole === InitUserRole.CONTRIBUTOR;
  const tooltipContent = isViewerOrContributor ? t("This action is only available to evaluation owners.") : "";
  return (
    <div aria-label={t("vendor options menu")}>
      <TooltipV2
        title={tooltipContent}
        rootClassName={"gx-workspace-header-awarded-tool-tip"}
        placement="left"
        PopperProps={{ modifiers: { offset: { offset: "0px, 0px" } } }}
      >
        <div>
          <PopoverMenu
            disabled={isViewerOrContributor}
            iconType="kebab"
            menuItems={menuItems}
            onClick={handleMenuClick}
            rootClassName={classNames("gx-workspace-vendor-options")}
            showSelectedOption={false}
            useIconButton={true}
          />
        </div>
      </TooltipV2>
    </div>
  );
};

export default VendorOptionsPopoverMenu;
