import { AppPaths, NewAppPaths, TabNames } from "../../lib/types";
import { UUID } from "gx-npm-lib";

export const tabIndexToData = Object.freeze([
  {
    name: TabNames.PROFILE,
    pathName: NewAppPaths.PRODUCT_PROFILE_TAB,
    url: (initId: UUID, initProdId: UUID) =>
      `/s/evaluation/${initId}/product/${initProdId}${NewAppPaths.PRODUCT_PROFILE_TAB}`,
  },
  {
    name: TabNames.MY_SCORESHEET,
    pathName: NewAppPaths.PRODUCT_SCORESHEET_TAB,
    url: (initId: UUID, initProdId: UUID) =>
      `/s/evaluation/${initId}/product/${initProdId}${NewAppPaths.PRODUCT_SCORESHEET_TAB}`,
  },
  {
    name: TabNames.RESPONSES,
    pathName: NewAppPaths.PRODUCT_RESPONSES_TAB,
    url: (initId: UUID, initProdId: UUID) =>
      `/s/evaluation/${initId}/product/${initProdId}${NewAppPaths.PRODUCT_RESPONSES_TAB}`,
  },
  {
    name: TabNames.DOCUMENTS,
    pathName: NewAppPaths.PRODUCT_DOCUMENTS_TAB,
    url: (initId: UUID, initProdId: UUID) =>
      `/s/evaluation/${initId}/product/${initProdId}${NewAppPaths.PRODUCT_DOCUMENTS_TAB}`,
  },
]);

/**
 * @deprecated
 */
export const tabIndexToDataOld = Object.freeze([
  {
    name: TabNames.PROFILE,
    pathName: AppPaths.PRODUCT_PROFILE_TAB,
    url: (initId: UUID, initProdId: UUID) => `${AppPaths.PRODUCT_PROFILE_TAB}?id=${initId}&pid=${initProdId}`,
  },
  {
    name: TabNames.MY_SCORESHEET,
    pathName: AppPaths.PRODUCT_SCORESHEET_TAB,
    url: (initId: UUID, initProdId: UUID) => `${AppPaths.PRODUCT_SCORESHEET_TAB}?id=${initId}&pid=${initProdId}`,
  },
  {
    name: TabNames.RESPONSES,
    pathName: AppPaths.PRODUCT_RESPONSES_TAB,
    url: (initId: UUID, initProdId: UUID) => `${AppPaths.PRODUCT_RESPONSES_TAB}?id=${initId}&pid=${initProdId}`,
  },
  {
    name: TabNames.DOCUMENTS,
    pathName: AppPaths.PRODUCT_DOCUMENTS_TAB,
    url: (initId: UUID, initProdId: UUID) => `${AppPaths.PRODUCT_DOCUMENTS_TAB}?id=${initId}&pid=${initProdId}`,
  },
]);
