import { AppBar, makeStyles, Tab, Tabs } from "@material-ui/core";
import classNames from "classnames";
import { InitProdState } from "gx-npm-lib";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import * as singleSpa from "single-spa";
import { useWorkspaceHeaderContext } from "../../context/workspace-header.context";
import { tabIndexToData } from "./tab-menu.lib";
import { tabMenuComponentStyles as styles } from "./tab-menu.styles";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";
import { useFeatureFlag } from "gx-npm-ui";
export const isTabsShown = (isScrolling: boolean, productState: InitProdState) => {
  return !isScrolling && productState && productState !== InitProdState.LISTED;
};

const useStyles = makeStyles(() => styles);

const TabMenu = ({ isScrolling = false }: { isScrolling?: boolean }) => {
  const isFFGCOM3606 = useFeatureFlag(GCOM_3606__fontUpdate);
  const { t } = useTranslation();
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const { initId, initProdId, productState, setSelectedTab } = useWorkspaceHeaderContext();
  const location = useLocation();
  const tabIndexToDataNewRoute = tabIndexToData;

  useEffect(() => {
    const pathName = window.location.pathname;
    tabIndexToDataNewRoute.forEach((tab, index) => {
      if (pathName.endsWith(tab.pathName)) {
        setTabIndex(index);
        setSelectedTab(tab.name);
      }
    });
  }, [setSelectedTab, location, tabIndexToDataNewRoute]);

  useEffect(() => {
    setSelectedTab(tabIndexToDataNewRoute[tabIndex].name);
  }, [tabIndex, setSelectedTab, tabIndexToDataNewRoute]);

  const handleChange = (newIndex: number) => {
    setTabIndex(newIndex);
    singleSpa.navigateToUrl(tabIndexToDataNewRoute[newIndex].url(initId, initProdId));
  };

  const renderTabs = () => {
    return tabIndexToDataNewRoute.map((tab, index) => {
      return (
        <Tab {...(isFFGCOM3606 && { classes: { wrapper: classes.tabClass } })} key={`tab-${index}`} label={tab.name} />
      );
    });
  };

  return (
    <>
      {isTabsShown(isScrolling, productState) && (
        <section
          aria-label={`${t("tab menu selected tab is")} ${tabIndexToDataNewRoute[tabIndex].name}`}
          className={classNames(classes.root, isScrolling && "scrolling")}
        >
          <AppBar color="default" elevation={0} position="relative">
            <Tabs
              classes={{ indicator: classes.indicator }}
              indicatorColor="primary"
              onChange={(_event, index) => handleChange(index)}
              scrollButtons="auto"
              textColor="primary"
              variant="scrollable"
              value={tabIndex}
            >
              {renderTabs()}
            </Tabs>
          </AppBar>
        </section>
      )}
    </>
  );
};

export default TabMenu;
