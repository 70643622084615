import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleEvent } from "gx-npm-lib";
import { Button, Dialog, variantType } from "gx-npm-ui";
import { WebSocketMessageEvents } from "../../lib/types";

type NavigationDialogProps = {
  handleConfirm: () => void;
  isOpen: boolean;
  userName: string;
  wsMessageEvent: WebSocketMessageEvents;
};

const NavigationDialog = ({
  handleConfirm,
  isOpen = false,
  userName = "",
  wsMessageEvent = WebSocketMessageEvents.NONE,
}: NavigationDialogProps) => {
  const { t } = useTranslation();
  const blankDialogText = useMemo(() => {
    return { title: "", body: "", buttons: { confirm: "" } };
  }, []);

  const [dialogText, setDialogText] = useState(blankDialogText);

  const supportedEvents = useMemo(() => {
    return {
      [WebSocketMessageEvents.INITIATIVE_DELETED]: {
        title: t("This initiative has been deleted"),
        body: t("has deleted this initiative and you no longer can view it."),
        buttons: {
          confirm: t("back to my initiatives"),
        },
      },
      [WebSocketMessageEvents.INITIATIVE_ACCESS_REVOKED]: {
        title: t("You no longer have access to this initiative"),
        body: t("has revoked your access to edit and view this initiative."),
        buttons: {
          confirm: t("back to my initiatives"),
        },
      },
      [WebSocketMessageEvents.PRODUCT_REMOVED_FROM_EVAL]: {
        title: t("Vendor removed from evaluation"),
        body: t("has removed this vendor from the active evaluation."),
        buttons: {
          confirm: t("back to eval overview"),
        },
      },
      [WebSocketMessageEvents.NONE]: blankDialogText,
    };
  }, [blankDialogText, t]);

  const isSupportedEvent = wsMessageEvent in supportedEvents;

  useEffect(() => {
    if (isSupportedEvent) {
      setDialogText(supportedEvents[wsMessageEvent as keyof typeof supportedEvents]);
    }
  }, [isSupportedEvent, supportedEvents, wsMessageEvent]);

  return (
    <Dialog
      open={isOpen && isSupportedEvent}
      title={dialogText.title}
      body={
        <p aria-label="nav-dialog-body" style={{ marginBottom: 20 }}>
          <span>{`${userName} ${dialogText.body}`}</span>
        </p>
      }
      footer={
        <div style={{ display: "flex", float: "right" }}>
          <div style={{ marginLeft: 24 }}>
            <Button rootClassName="btn-primary" onClick={() => handleEvent(handleConfirm)}>
              {dialogText.buttons.confirm}
            </Button>
          </div>
        </div>
      }
      variant={variantType.dialog}
    />
  );
};

export default NavigationDialog;
