import * as singleSpa from "single-spa";
import { useTranslation } from "react-i18next";
import styles from "./branding-header.styles";
import { makeStyles } from "@material-ui/core";
import {
  Button,
  Loader,
  useFeatureFlag,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { ArrowBackIcon, BuySmartLogoOrangeStacked } from "gx-npm-icons";
import ActiveViewers from "./components/active-viewers.component";
import { colorPalette } from "gx-npm-common-styles";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";
import classNames from "classnames";

const useStyles = makeStyles(() => styles);

type BrandingHeaderProps = {
  activeViewers?: Array<string>;
  backBtnText?: string;
  backURL?: string;
  initName?: string;
  isLoading?: boolean;
};

const BrandingHeader = ({
  activeViewers = [],
  backBtnText = "",
  backURL = "",
  initName = "",
  isLoading = true,
}: BrandingHeaderProps) => {
  const isFFGCOM3606 = useFeatureFlag(GCOM_3606__fontUpdate);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = () => {
    singleSpa.navigateToUrl(backURL);
  };

  return (
    <div className={classes.brandHeader}>
      <div className={classNames(isFFGCOM3606 ? classes.backButtonGCOM3606 : classes.backButton)}>
        <Button rootClassName="btn-tertiary" onClick={handleClick}>
          <ArrowBackIcon fillPath={colorPalette.interactions.defaultCta.hex} />
          {t("Back to ")}
          {backBtnText}
        </Button>
        <div className={classes.backButtonDivider} />
        {isLoading ? (
          <Loader rootClassName={classes.brandingHeaderNameLoader} />
        ) : (
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent rootClassName={"init-name"} styling={"p1"} color={"iron"} boldness={"regular"}>
                {initName ? initName : t("Untitled evaluation")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p>{initName ? initName : t("Untitled evaluation")}</p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        )}
        <ActiveViewers viewerList={activeViewers} />
      </div>
      <div className={classes.brandLogo}>
        <BuySmartLogoOrangeStacked />
      </div>
    </div>
  );
};

export default BrandingHeader;
