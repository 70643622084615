import { colorPalette } from "gx-npm-common-styles";
const lightBerry = colorPalette.interactions.lightBerry.hex;

const legalInlineAlertStyles = {
  alertWrapper: {
    maxWidth: 1512,
    minWidth: 900,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 56,
    paddingRight: 56,
    "@media (max-width:1239px)": {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  fullWindowAlertBackground: {
    width: "100%",
    backgroundColor: lightBerry,
  },
  collapseContainer: {},
  alertContainer: {
    alignItems: "center",
    backgroundColor: lightBerry,
    display: "flex",
    height: "auto",
    padding: "16px 0px 16px 0px",
    width: "100%",
    "& .gx-inline-alert-message": {
      color: colorPalette.neutrals.coal.hex,
      marginLeft: "16px",
      maxWidth: "600px",
    },
  },
  buttonContainer: {
    alignItems: "center",
    display: "flex",
    marginLeft: "auto",
    marginRight: 0,
    "& .mini-btn:hover, .mini-btn:focus": {
      backgroundColor: colorPalette.interactions.lightBerry.hex,
    },
  },
};

export default legalInlineAlertStyles;
