import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { UsernameDisplay, TooltipV2 } from "gx-npm-ui";
import { activeViewersClasses as styles } from "./active-viewers.styles";

const useStyles = makeStyles(() => styles);

const maxViewerDisplay = 3;

type ActiveViewerProps = { viewerList?: Array<string> };
const ActiveViewers = ({ viewerList = [] }: ActiveViewerProps) => {
  const viewersCount = viewerList.length;
  const additionalViewerCount = viewersCount - maxViewerDisplay;
  const addViewerTooTipText = viewerList.slice(maxViewerDisplay).map((viewer) => (
    <span key={viewer}>
      {viewer}
      <br />
    </span>
  ));
  const classes = useStyles();

  return (
    <div className={classNames(classes.avatars)}>
      {viewerList.slice(0, maxViewerDisplay).map((viewerName) => {
        return (
          <div key={viewerName}>
            <TooltipV2 rootClassName={classNames(classes.brandViewerHeader)} title={viewerName} placement="bottom">
              <div>
                <UsernameDisplay name={viewerName} showName={false} invertColor={true} />
              </div>
            </TooltipV2>
          </div>
        );
      })}
      {additionalViewerCount > 0 && (
        <TooltipV2 title={addViewerTooTipText} placement="top" rootClassName={classNames(classes.brandViewerHeader)}>
          <div>
            <p
              className={classNames("p4", "medium-bold", classes.additionalViewersBadge)}
            >{`+${additionalViewerCount}`}</p>
          </div>
        </TooltipV2>
      )}
    </div>
  );
};

export default ActiveViewers;
