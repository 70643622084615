import { createGenerateClassName, StylesProvider } from "@material-ui/core";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import App from "./app.component";
import { WorkspaceHeaderContextProvider } from "./context/workspace-header.context";
import { SnackbarContextProvider } from "./context/snack-bar-banner/snack-bar.context";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import HandleRedirects from "./handle-redirects";

const generateClassName = createGenerateClassName({
  seed: "workspace-header-class",
});

const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <StylesProvider generateClassName={generateClassName}>
        <WorkspaceHeaderContextProvider>
          <SnackbarContextProvider>
            <BrowserRouter>
              <Routes>
                <Route path={"/s/evaluation/:initiativeId/product/:initiativeProductId/:targetApp"} element={<App />} />
                <Route path={"*"} element={<HandleRedirects />} />
              </Routes>
            </BrowserRouter>
          </SnackbarContextProvider>
        </WorkspaceHeaderContextProvider>
      </StylesProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
