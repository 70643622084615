import { InitProdState, InitState, InitUserRole, UUID } from "gx-npm-lib";
import { createContext, ReactNode, useContext, useState } from "react";
import { InitStatus, TabNames, WorkspaceHeaderContextValue } from "../lib/types";

const WorkspaceHeaderContext = createContext<WorkspaceHeaderContextValue | null>(null);

const useWorkspaceHeaderContext = () => {
  const responseOptionsContext = useContext(WorkspaceHeaderContext);

  if (responseOptionsContext === null) {
    throw new Error("useWorkspaceHeaderContext must be inside a WorkspaceHeaderContextProvider");
  }
  return responseOptionsContext;
};

const WorkspaceHeaderContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [activeViewers, setActiveViewers] = useState<string[]>([""]);
  const [awardedProductImageLoc, setAwardedProductImageLoc] = useState<string>("");
  const [awardedProductName, setAwardedProductName] = useState<string>("");
  const [initId, setInitId] = useState<UUID>("");
  const [initName, setInitName] = useState<string>("");
  const [initProdId, setInitProdId] = useState<UUID>("");
  const [initState, setInitState] = useState<InitState>(InitState.ACTIVE);
  const [initStatus, setInitStatus] = useState<InitStatus>(InitStatus.PLANNING);
  const [initStateUpdateDate, setInitStateUpdateDate] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [productImageLoc, setProductImageLoc] = useState<string>("");
  const [productState, setProductState] = useState<InitProdState>(InitProdState.LISTED);
  const [savingCount, setSavingCount] = useState<number>(0);
  const [sourcePage, setSourcePage] = useState<string>("");
  const [surveyId, setSurveyId] = useState<UUID>("");
  const [userRole, setUserRole] = useState<InitUserRole | "">("");
  const [isSurveySubmitted, setIsSurveySubmitted] = useState<boolean>(false);
  const [hasRequestedDocuments, setHasRequestedDocuments] = useState<boolean>(false);
  const [isSurveyLegalDismissed, setIsSurveyLegalDismissed] = useState<boolean>(false);
  const [isLegalAlertShown, setIsLegalAlertShown] = useState(false);
  const [selectedTab, setSelectedTab] = useState<TabNames | "">("");

  const contextValue = {
    selectedTab,
    isLegalAlertShown,
    activeViewers,
    awardedProductImageLoc,
    awardedProductName,
    initId,
    initName,
    initState,
    initStateUpdateDate,
    initStatus,
    initProdId,
    productName,
    productImageLoc,
    productState,
    savingCount,
    sourcePage,
    surveyId,
    userRole,
    isSurveySubmitted,
    hasRequestedDocuments,
    isSurveyLegalDismissed,
    setSelectedTab,
    setIsLegalAlertShown,
    setActiveViewers,
    setAwardedProductImageLoc,
    setAwardedProductName,
    setInitId,
    setInitName,
    setInitProdId,
    setInitState,
    setInitStateUpdateDate,
    setInitStatus,
    setProductName,
    setProductImageLoc,
    setProductState,
    setSavingCount,
    setSourcePage,
    setSurveyId,
    setUserRole,
    setIsSurveySubmitted,
    setHasRequestedDocuments,
    setIsSurveyLegalDismissed,
  };

  return <WorkspaceHeaderContext.Provider value={contextValue}>{props.children}</WorkspaceHeaderContext.Provider>;
};

export { WorkspaceHeaderContext, WorkspaceHeaderContextProvider, useWorkspaceHeaderContext };
