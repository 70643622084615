import React, { Fragment } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Collapse, makeStyles } from "@material-ui/core";
import { MiniButton, Paragraph } from "gx-npm-ui";
import styles from "./legal-inline-alert.styles";
import { colorPalette } from "gx-npm-common-styles";
import { InfoIcon } from "gx-npm-icons";
import { isValidResponse, postAsyncRequest } from "gx-npm-lib";
import { useWorkspaceHeaderContext } from "../../context/workspace-header.context";
import { useSnackbarContext } from "../../context/snack-bar-banner/snack-bar.context";

const useStyles = makeStyles(() => styles);
type AlertProps = { isShown: boolean; initId: string };
const LegalInlineAlert: React.FC<AlertProps> = ({ isShown = false, initId = "" }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { setIsSurveyLegalDismissed } = useWorkspaceHeaderContext();
  const { setShowSnackBar, setSnackbarIsError } = useSnackbarContext();
  const handleDismissClick = async () => {
    const url = `/api/v2/initiatives/${initId}/workspace/dismiss-survey-legal`;
    const response = await postAsyncRequest(url);
    if (isValidResponse(response)) {
      setIsSurveyLegalDismissed(true);
    } else {
      setSnackbarIsError(true);
      setShowSnackBar(true);
    }
  };
  return (
    <Fragment>
      <div className={classes.fullWindowAlertBackground}>
        <div className={classes.alertWrapper}>
          <Collapse className={classes.collapseContainer} in={isShown}>
            <div className={classes.alertContainer}>
              <InfoIcon fillPath={colorPalette.neutrals.coal.hex} />
              <Paragraph boldness="medium" rootClassName="gx-inline-alert-message" type="p3">
                {t(
                  "Vendor responses in BuySmart are not reviewed by Gartner Experts and do not necessarily represent the views or opinions of Gartner."
                )}
              </Paragraph>
              <div className={classes.buttonContainer}>
                <div>
                  <MiniButton onClick={handleDismissClick} rootClassName={classNames("btn-tertiary ")}>
                    {t("DISMISS")}
                  </MiniButton>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </Fragment>
  );
};

export default LegalInlineAlert;
