import { colorPalette } from "gx-npm-common-styles";

const colorQuartz = colorPalette.neutrals.quartz.hex;
const colorIron = colorPalette.neutrals.iron.hex;
const colorSilver = colorPalette.neutrals.silver.hex;

const brandingHeaderStyles = {
  brandHeader: {
    alignItems: "center",
    backgroundColor: colorQuartz,
    color: colorIron,
    display: "flex",
    justifyContent: "space-between",
    minHeight: "72px",
    paddingRight: "24px",
    paddingLeft: "24px",
    position: "relative" as const,
    zIndex: 1105,
  },
  brandLogo: {},
  backButton: {
    display: "flex",
    alignItems: "center",
    "& p": {
      display: "inline-block",
      margin: 0,
      fontSize: "18px",
      fontWeight: 600,
    },
  },
  backButtonDivider: {
    width: "1px",
    height: "72px",
    backgroundColor: colorSilver,
    marginRight: "24px",
  },
  brandingHeaderNameLoader: {
    left: 0,
    position: "relative" as const,
  },
};

export default brandingHeaderStyles;
