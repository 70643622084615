import { colorPalette } from "gx-npm-common-styles";

const colorSilver = colorPalette.neutrals.silver.hex;

const workspaceHeaderAppStyles = {
  containerHeaderTabsInside: {
    display: "flex" as string as "flex",
    flexDirection: "column" as string as "column",
    justifyContent: "center",
    margin: "0 auto",
    maxWidth: 1396,
  },
  containerHeaderTabsOutside: {
    backgroundColor: colorPalette.basic.white.hex,
    paddingLeft: 56,
    paddingRight: 56,
    transition: "padding 0.5s ease-in-out",
    width: "100%",
    "@media (max-width:1239px)": {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  content: {
    borderBottom: `1px solid ${colorSilver}`,
    position: "fixed" as string as "fixed",
    top: 0,
    width: "100%",
    zIndex: 1106,
    "&.scrolling": {
      borderBottom: "none",
      boxShadow: "0px 4px 12px rgba(20, 19, 18, 0.16)",
    },
  },
  root: {},
};

export { workspaceHeaderAppStyles };
