import { colorPalette } from "gx-npm-common-styles";

const colorIron = colorPalette.neutrals.iron.hex;
const colorQuartz = colorPalette.neutrals.quartz.hex;
const colorWhite = colorPalette.basic.white.hex;

const awardProductDialogStyles = {
  leftItems: {
    backgroundColor: colorQuartz,
    display: "flex",
    height: "444px",
    marginBottom: "20px",
    marginLeft: "-35px",
    marginTop: "42px",
    position: "relative" as string as "relative",
    width: "696px",
    "& .p3": {
      color: colorIron,
      marginLeft: 138,
      paddingTop: 270,
    },
    "& .p2": {
      color: colorIron,
      position: "absolute",
      marginTop: 336,
      paddingLeft: 180,
    },
  },
  confirmVendor: {
    alignItems: "center",
    backgroundColor: colorQuartz,
    display: "flex",
    flexDirection: "column" as string as "column",
    height: "444px",
    position: "relative" as string as "relative",
    marginLeft: "-35px",
    marginTop: "42px",
    width: "696px",
    "& .p2": {
      marginTop: 36,
    },
  },
  confirmSelectedVendorIcons: {
    alignItems: "center",
    backgroundColor: colorWhite,
    borderRadius: "9.6px",
    display: "flex",
    flexDirection: "column" as string as "column",
    gap: 20,
    height: 156,
    marginBottom: 20,
    marginTop: 91,
    width: 156,
  },
  footerConfirmBtn: {
    display: "inline-block",
    "& .btn-primary": {
      color: colorWhite,
    },
  },
  footer: {
    display: "flex",
    float: "right" as string as "right",
    gap: 28,
    paddingRight: 4,
  },
  awardedVendorIcons: {
    alignItems: "center",
    backgroundColor: colorWhite,
    borderRadius: "9.6px",
    display: "flex",
    height: 114,
    justifyContent: "center",
    marginLeft: 136,
    marginTop: 144,
    position: "absolute" as string as "absolute",
    width: 114,
  },
  newAwardedVendorIcons: {
    alignItems: "center",
    backgroundColor: colorWhite,
    borderRadius: "9.6px",
    display: "flex",
    flexDirection: "column" as string as "column",
    gap: 20,
    height: 219,
    marginTop: 82,
    width: 219,
  },
  rightArrow: {
    "& svg": {
      marginLeft: 24,
      marginRight: 24,
      marginTop: 200,
    },
    "& img": {
      marginLeft: 24,
      marginRight: 24,
      marginTop: 200,
    },
  },
  title: {
    paddingLeft: 12,
    "& .workspace-header-class-MuiIconButton-root": {
      display: "inline-grid",
      height: 0,
      marginLeft: 259,
      paddingBottom: "15px !important",
      width: 0,
    },
    "&$selectedTitle": {
      "& .workspace-header-class-MuiIconButton-root": {
        display: "unset",
      },
    },
  },
  selectedTitle: {},
  "@global": {
    ".gx-award-dialog": {
      "& .workspace-header-class-MuiPaper-root.workspace-header-class-MuiDialog-paper": {
        overflowX: "hidden",
        overflowY: "auto !important",
        maxWidth: 698,
      },
    },
  },
};

export default awardProductDialogStyles;
