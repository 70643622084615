import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  ButtonLoader,
  Dialog,
  IconButton,
  ProductLogo,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { handleEvent } from "gx-npm-lib";
import { ArrowRight, MultiplyIcon, SelectedVendorIcon, SelectedVendorIconSmall } from "gx-npm-icons";
import styles from "./award-product-dialog.styles";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

type ActionDialogProps = {
  awardedProductName: string;
  awardedProductImageLoc: string;
  isLoading: boolean;
  isOpen: boolean;
  selectionImageLoc: string;
  selectionName: string;
  onClose?: () => void;
  onConfirm?: () => void;
};

const useStyles = makeStyles(() => styles);
const AwardProductDialog = ({
  awardedProductName,
  awardedProductImageLoc,
  isOpen = false,
  isLoading = false,
  selectionImageLoc,
  selectionName,
  onClose,
  onConfirm,
}: ActionDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const labelTitle = awardedProductName ? t("Change your selected vendor?") : t("Confirm vendor selection");

  return (
    <Dialog
      maxDialogWidth="698px"
      open={isOpen}
      rootClassName={classNames("gx-award-dialog")}
      title={
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent
              styling={"h4"}
              boldness={"medium"}
              color={"carbon"}
              rootClassName={classNames(classes.title, awardedProductName && classes.selectedTitle)}
            >
              {labelTitle}
              <IconButton onClick={() => handleEvent(onClose)}>
                <MultiplyIcon title="Cancel" />
              </IconButton>
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <div className={classNames(classes.title, awardedProductName && classes.selectedTitle)}>
              {labelTitle}
              <IconButton onClick={() => handleEvent(onClose)}>
                <MultiplyIcon title="Cancel" />
              </IconButton>
            </div>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      }
      body={
        <Fragment>
          {awardedProductName && (
            <div className={classNames(classes.leftItems)}>
              <div className={classNames(classes.awardedVendorIcons)}>
                <ProductLogo imageHeight="56px" imageWidth="56px" logo={awardedProductImageLoc} />
              </div>
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent
                    rootClassName={classes.currentSelectTitleGCOM3606}
                    styling={"p3"}
                    boldness={"medium"}
                    color={"iron"}
                  >
                    {t("Current selection")}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <p className="p3">{t("Current selection")}</p>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>

              <div className={classNames(classes.rightArrow)}>
                <ArrowRight />
              </div>
              <div className={classNames(classes.newAwardedVendorIcons)}>
                <SelectedVendorIcon />
                <ProductLogo logo={selectionImageLoc} imageWidth="70px" imageHeight="70px" />
              </div>
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent
                    rootClassName={classes.changeSelectionTextGCOM3606}
                    styling={"p2"}
                    boldness={"regular"}
                    color={"iron"}
                  >
                    {t("You can change your selection later, if needed.")}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <p className="p2">{t("You can change your selection later, if needed.")}</p>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>
            </div>
          )}
          {!awardedProductName && (
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <div className={classNames(classes.confirmVendor)}>
                  <div className={classNames(classes.confirmSelectedVendorIcons)}>
                    <SelectedVendorIconSmall />
                    <ProductLogo logo={selectionImageLoc} imageWidth="56px" imageHeight="56px" />
                  </div>
                  <TypographyComponent styling={"h5"} boldness={"medium"} color={"carbon"}>
                    {selectionName}
                  </TypographyComponent>
                  <TypographyComponent
                    rootClassName={classes.selectTitleGCOM3606}
                    styling={"p2"}
                    boldness={"regular"}
                    color={"coal"}
                  >
                    {t("You can change your selection later, if needed.")}
                  </TypographyComponent>
                </div>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <div className={classNames(classes.confirmVendor)}>
                  <div className={classNames(classes.confirmSelectedVendorIcons)}>
                    <SelectedVendorIconSmall />
                    <ProductLogo logo={selectionImageLoc} imageWidth="56px" imageHeight="56px" />
                  </div>
                  <h5>{selectionName}</h5>
                  <p className="p2">{t("You can change your selection later, if needed.")}</p>
                </div>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          )}
        </Fragment>
      }
      footer={
        <div className={classNames(classes.footer)}>
          <div>
            <Button onClick={() => handleEvent(onClose)} rootClassName="btn-tertiary">
              {t("CANCEL")}
            </Button>
          </div>
          <div className={classNames(classes.footerConfirmBtn)}>
            <ButtonLoader btnClass="btn-primary" handleButtonClick={onConfirm} isLoading={isLoading}>
              {t("CONFIRM AND VIEW SUMMARY")}
            </ButtonLoader>
          </div>
        </div>
      }
    />
  );
};
export default AwardProductDialog;
