import { createContext, ReactNode, useContext, useState, Dispatch, SetStateAction } from "react";

type SnackbarContextValue = {
  snackbarIsError: boolean;
  setSnackbarIsError: Dispatch<SetStateAction<boolean>>;
  setShowSnackBar: Dispatch<SetStateAction<boolean>>;
  setSnackBarMessage: Dispatch<SetStateAction<string>>;
  showSnackBar: boolean;
  snackBarMessage: string;
};

const SnackbarContext = createContext<SnackbarContextValue | null>(null);

const useSnackbarContext = () => {
  const responseOptionsContext = useContext(SnackbarContext);

  if (responseOptionsContext === null) {
    throw new Error("useSnackbarContext must be inside a SnackbarContextProvider");
  }
  return responseOptionsContext;
};

const SnackbarContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [snackbarIsError, setSnackbarIsError] = useState<boolean>(false);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  const contextValue = {
    snackbarIsError,
    setSnackbarIsError,
    setSnackBarMessage,
    setShowSnackBar,
    showSnackBar,
    snackBarMessage,
  };

  return <SnackbarContext.Provider value={contextValue}>{props.children}</SnackbarContext.Provider>;
};

export { SnackbarContext, SnackbarContextProvider, useSnackbarContext };
