import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorBlueBerry = colorPalette.interactions.blueBerry.hex;
const colorCoal = colorPalette.neutrals.coal.hex;
const colorLightBerry = colorPalette.interactions.lightBerry.hex;
const colorLemon = colorPalette.status.lemon.hex;
const colorLightLemon = colorPalette.status.lightLemon.hex;
const colorPearl = colorPalette.neutrals.pearl.hex;
const colorPoisonCherry = colorPalette.status.poisonCherry.hex;
const colorSilver = colorPalette.neutrals.silver.hex;
const defaultCta = colorPalette.interactions.defaultCta.hex;

const weightAmtSemiBold = weightPalette.semiBold.amount;
const weightVarSemiBold = weightPalette.semiBold.variation;

const productStateActionStyles = {
  root: {
    display: "flex" as string as "flex",
    marginLeft: 32,
    marginRight: 24,
    "& $selectedVendorIcon": {
      "& svg": { borderRadius: "4px 0px 0px 0px" },
    },
    "& $popoverMenu": {
      "&.awarded": {
        backgroundColor: colorLightLemon,
        "& .gx-popover-menu-item a": {
          color: colorPoisonCherry,
        },
      },
      "&.ineval": {
        backgroundColor: colorLightBerry,
      },
      "&.screened-out": {
        backgroundColor: colorPearl,
      },
      "&.select": {
        padding: "10px 12px",
        width: 220,
        "& .gx-popover-menu-selected": {
          width: "100%",
          "& p": {
            color: colorCoal,
            fontSize: "16px",
            fontVariationSettings: weightVarSemiBold,
            fontWeight: weightAmtSemiBold,
          },
        },
        "& .gx-popover-menu-list": {
          marginTop: 6,
          borderRadius: 4,
          border: `2px solid ${colorSilver}`,
          boxShadow: "0px 3px 3px 1px rgba(20, 19, 18, 0.10)",
          width: "auto",
        },
        "&.awarded": {
          border: `2px solid ${colorLightLemon}`,
          borderRadius: "0px 4px 4px 0px",
          "& .gx-popover-menu-selected": {
            "& p": {
              color: "#5B4300",
            },
          },
          "& svg path": {
            fill: "#5B4300",
          },
        },
        "&.ineval, &.screened-out": {
          borderRadius: "4px",
        },
        "&.ineval": {
          border: `2px solid ${colorLightBerry}`,
          "& .gx-popover-menu-selected": {
            "& p": {
              color: colorBlueBerry,
            },
          },
          "& svg path": {
            fill: colorBlueBerry,
          },
        },
        "&.screened-out": {
          border: `2px solid ${colorPearl}`,
          "& svg path": {
            fill: colorCoal,
          },
        },
        "&.open": {
          "&.awarded": {
            border: `2px solid ${colorLemon}`,
          },
          "&.ineval": {
            border: `2px solid ${colorBlueBerry}`,
          },
          "&.screened-out": {
            border: `2px solid ${colorCoal}`,
          },
        },
      },
    },
  },
  rootGCOM3695: {
    "& $popoverMenu": {
      "&.select": {
        "&.ineval": {
          "& .gx-popover-menu-selected": {
            "& p": {
              color: defaultCta,
            },
          },
          "& svg path": {
            fill: defaultCta,
          },
        },
        "&.open": {
          "&.ineval": {
            border: `2px solid ${defaultCta}`,
          },
        },
      },
    },
  },
  popoverMenu: {},
  selectedVendorIcon: {},
};

export default productStateActionStyles;
