import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { defaultToEmptyString, InitProdState, useCaptureEventsV2, useUserState } from "gx-npm-lib";
import { createStateSubscription, destroyStateSubscription } from "gx-npm-messenger-util";
import { FreeTrialBannerComponent } from "gx-npm-ui";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useWorkspaceHeaderContext } from "./context/workspace-header.context";
import { useWorkspaceHeaderLoad } from "./context/load/use-load-workspace-header.hook";
import { useSnackbarContext } from "./context/snack-bar-banner/snack-bar.context";
import { TabNames } from "./lib/types";
import Spacer from "./sections/spacer/spacer.component";
import BrandingHeader from "./sections/branding-header/branding-header.component";
import ProductHeader from "./sections/product-header/product-header.component";
import SelectedProductBanner from "./sections/selected-product-banner/selected-product-banner.component";
import StateRestoreBanner from "./sections/state-restore-banner/state-restore-banner.component";
import TabMenu from "./sections/tab-menu/tab-menu.component";
import LegalInlineAlert from "./ui/legal-inline-alert/legal-inline-alert.component";
import Snackbar from "./ui/snack-bar/snack-bar.component";
import { workspaceHeaderAppStyles as styles } from "./app.component.styles";
import { ClientEvent } from "./app.constants";
import AppWebSocket from "./app.ws";

const useStyles = makeStyles(() => styles);
const App = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const appRef = useRef<HTMLInputElement>(null);
  const [backBtnText, setBackBtnText] = useState("");
  const [backURL, setBackUrl] = useState("");
  const [isScreenedOut, setIsScreenedOut] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const { setShowSnackBar, setSnackbarIsError } = useSnackbarContext();
  const { hasError, isLoading } = useWorkspaceHeaderLoad();
  const location = useLocation();
  const { initiativeProductId, initiativeId } = useParams();
  const {
    activeViewers,
    initId,
    initName,
    productState,
    sourcePage,
    setInitId,
    setInitProdId,
    setSourcePage,
    hasRequestedDocuments,
    isSurveySubmitted,
    isSurveyLegalDismissed,
    selectedTab,
    isLegalAlertShown,
    setIsLegalAlertShown,
  } = useWorkspaceHeaderContext();
  const captureEvents = useCaptureEventsV2();
  const { freeTrialDaysRemaining, freeTrialRole } = useUserState();

  useEffect(() => {
    const subscription = createStateSubscription(
      "WORKSPACE_HEADER_SOURCE_PAGE",
      (data: { sourcePage: string } = { sourcePage: "overview" }) => {
        setSourcePage(data.sourcePage);
      }
    );
    setInitId(defaultToEmptyString(initiativeId));
    setInitProdId(defaultToEmptyString(initiativeProductId));
    return () => {
      destroyStateSubscription(subscription);
    };
  }, [setInitId, setInitProdId, setSourcePage, initiativeId, initiativeProductId, location]);

  useEffect(() => {
    if (!selectedTab || !isSurveySubmitted || isSurveyLegalDismissed) {
      setIsLegalAlertShown(false);
      return;
    }
    const isResponsesBannerShown = selectedTab === TabNames.RESPONSES;
    const isDocumentsBannerShown = selectedTab === TabNames.DOCUMENTS && hasRequestedDocuments;
    setIsLegalAlertShown(isResponsesBannerShown || isDocumentsBannerShown);
  }, [selectedTab, hasRequestedDocuments, isSurveyLegalDismissed, isSurveySubmitted, setIsLegalAlertShown]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsScreenedOut(productState === InitProdState.SCREENED_OUT);
  }, [productState]);

  useEffect(() => {
    if (hasError) {
      setSnackbarIsError(true);
      setShowSnackBar(true);
    }
  }, [hasError, setShowSnackBar, setSnackbarIsError]);

  useEffect(() => {
    const sourcePageName: { [sourcePage: string]: string } = {
      ["vendor-list"]: t("vendor list"),
      ["overview"]: t("overview"),
      ["selection"]: t("selection"),
      ["questionnaire"]: t("questionnaire"),
      ["scorecard"]: t("scorecard"),
    };
    const sourcePageUrl: { [sourcePage: string]: string } = {
      ["vendor-list"]: `/s/evaluation/${initId}/products`,
      ["overview"]: `/s/evaluation/${initId}/overview`,
      ["selection"]: `/s/evaluation/${initId}/selection`,
      ["questionnaire"]: `/s/evaluation/${initId}/questionnaire`,
      ["scorecard"]: `/s/evaluation/${initId}/scorecard`,
    };
    const defaultSourcePage = "questionnaire";
    setBackBtnText(sourcePageName[sourcePage || defaultSourcePage]);
    setBackUrl(sourcePageUrl[sourcePage || defaultSourcePage]);
  }, [sourcePage, initId, t]);

  useEffect(() => {
    if (!initiativeId || !initiativeProductId) {
      return;
    }
    let eventType;
    if (selectedTab === TabNames.RESPONSES && isSurveySubmitted) {
      eventType = ClientEvent.INITIATIVE_PRODUCT_WORKSPACE_RESPONSES_PAGE_VIEWED;
    } else if (selectedTab === TabNames.DOCUMENTS && isSurveySubmitted && hasRequestedDocuments) {
      eventType = ClientEvent.INITIATIVE_PRODUCT_WORKSPACE_DOCUMENTS_PAGE_VIEWED;
    } else {
      return;
    }
    captureEvents([{ eventType, metaData: { initiativeId, initProductId: initiativeProductId } }]);
  }, [captureEvents, initiativeId, initiativeProductId, selectedTab, hasRequestedDocuments, isSurveySubmitted]);

  useEffect(() => {
    if (!initiativeId || !initiativeProductId) {
      return;
    }
    let eventType;
    if (selectedTab === TabNames.MY_SCORESHEET) {
      eventType = ClientEvent.INITIATIVE_PRODUCT_WORKSPACE_SCORESHEET_PAGE_VIEWED;
    } else if (selectedTab === TabNames.PROFILE) {
      eventType = ClientEvent.INITIATIVE_PRODUCT_WORKSPACE_PROFILE_PAGE_VIEWED;
    } else {
      return;
    }
    captureEvents([{ eventType, metaData: { initiativeId, initProductId: initiativeProductId } }]);
  }, [captureEvents, initiativeId, initiativeProductId, selectedTab]);

  return (
    <div
      aria-label={`${t("vendor header")}${hasError ? " " + t("load error") : ""}`}
      className={classes.root}
      ref={appRef}
    >
      {freeTrialRole && (
        <FreeTrialBannerComponent daysRemaining={freeTrialDaysRemaining} freeTrialRole={freeTrialRole} />
      )}
      <div className={classNames(classes.content, isScrolling && "scrolling")}>
        <StateRestoreBanner />
        <BrandingHeader
          activeViewers={activeViewers}
          initName={initName}
          backBtnText={backBtnText}
          backURL={backURL}
          isLoading={isLoading}
        />
        <SelectedProductBanner isScrolling={isScrolling} />
        <div className={classes.containerHeaderTabsOutside}>
          <div className={classes.containerHeaderTabsInside}>
            <ProductHeader
              hasError={hasError}
              isLoading={isLoading}
              isScreenedOut={isScreenedOut}
              isScrolling={isScrolling}
            />
            <TabMenu isScrolling={isScrolling} />
          </div>
        </div>
      </div>
      <Spacer isScrolling={isScrolling} />
      <Snackbar />
      <LegalInlineAlert initId={initId} isShown={isLegalAlertShown} />
      <AppWebSocket />
    </div>
  );
};

export default App;
