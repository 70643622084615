import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useWorkspaceHeaderContext } from "../../context/workspace-header.context";
import {
  brandHeaderHeight,
  currentlySelectedBannerHeight,
  productHeaderHeight,
  productHeaderHeightScrolled,
  restoreBannerHeight,
  tabsHeight,
} from "../../lib/header-styles";
import { isSelectedProductBannerShown } from "../selected-product-banner/selected-product-banner.component";
import { isStateRestoreBannerShown } from "../state-restore-banner/state-restore-banner.component";
import { isTabsShown } from "../tab-menu/tab-menu.component";
import { spacerStyles as styles } from "./spacer.styles";

const useStyles = makeStyles(() => styles);

const Spacer = ({ isScrolling = false }: { isScrolling?: boolean }) => {
  const classes = useStyles();

  const { awardedProductName, initState, productName, productState, initStatus } = useWorkspaceHeaderContext();

  let spacerHeight = brandHeaderHeight + (isScrolling ? productHeaderHeightScrolled : productHeaderHeight);

  if (isTabsShown(isScrolling, productState)) {
    spacerHeight = spacerHeight + tabsHeight;
  }

  if (isStateRestoreBannerShown(initState)) {
    spacerHeight = spacerHeight + restoreBannerHeight;
  }

  if (isSelectedProductBannerShown(awardedProductName, isScrolling, productName, initStatus)) {
    spacerHeight = spacerHeight + currentlySelectedBannerHeight;
  }

  return <div className={classNames("gx-content-spacer", classes.spacer)} style={{ height: spacerHeight }} />;
};

export default Spacer;
