import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ProductLogo } from "gx-npm-ui";
import { selectedProductRootDefaultStyles as styles } from "./selected-product-banner.styles";
import { useWorkspaceHeaderContext } from "../../context/workspace-header.context";
import { InitStatus } from "../../lib/types";

export const isSelectedProductBannerShown = (
  awardedProductName: string,
  isScrolling: boolean,
  productName: string,
  initStatus: InitStatus
) => {
  return !isScrolling && initStatus === InitStatus.SELECTED && awardedProductName !== productName;
};

type SelectedProductBannerProps = {
  isScrolling?: boolean;
};

const useStyles = makeStyles(() => styles);
const SelectedProductBanner = ({ isScrolling = false }: SelectedProductBannerProps) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { initStatus, awardedProductImageLoc, awardedProductName, productName } = useWorkspaceHeaderContext();

  return (
    <Collapse
      className={classNames(classes.collapseRoot)}
      in={isSelectedProductBannerShown(awardedProductName, isScrolling, productName, initStatus)}
      timeout={{ enter: 400, exit: 500 }}
    >
      {awardedProductName && (
        <div className={classes.container}>
          <p className={classNames(classes.title, "p3")}>{`${t("Currently selected vendor")}:`}</p>
          <span className={classes.productLogo}>
            <ProductLogo imageHeight="24px" imageWidth="24px" logo={awardedProductImageLoc} />
          </span>
          <p className={classNames("semi-bold", "p2")}>{awardedProductName}</p>
        </div>
      )}
    </Collapse>
  );
};

export default SelectedProductBanner;
