import { colorPalette } from "gx-npm-common-styles";

const colorSilver = colorPalette.neutrals.silver.hex;
const colorCoal = colorPalette.neutrals.coal.hex;
const colorGartnerBlue = colorPalette.brand.gartnerBlue.hex;

const activeViewersClasses = {
  avatars: {
    alignItems: "center",
    display: "inline-flex",
    marginLeft: "24px",
    paddingLeft: "24px",
    borderLeft: `1px solid ${colorSilver}`,

    "& .gx-user": {
      marginRight: "-7px",
      paddingTop: "0px",
      "& div.UsernameDisplay-UI-MuiAvatar-circle": {
        border: `1px solid ${colorGartnerBlue}`,
        fontSize: "12px",
        height: "30px",
        lineHeight: "18px",
        width: "30px",
      },
    },
  },
  additionalViewersBadge: {
    alignItems: "center",
    backgroundColor: colorSilver,
    border: `1px solid ${colorGartnerBlue}`,
    borderRadius: "50%",
    color: colorCoal,
    display: "flex",
    height: "32px",
    justifyContent: "center",
    width: "32px",
    zIndex: 1,
  },
  brandViewerHeader: {
    width: "auto",
    top: "4px !important",
    left: "2px !important",
  },
};

export { activeViewersClasses };
