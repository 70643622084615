import * as singleSpa from "single-spa";
import { useTranslation } from "react-i18next";
import styles from "./branding-header.styles";
import { makeStyles } from "@material-ui/core";
import { Button, Loader, useFeatureFlag } from "gx-npm-ui";
import { ArrowBackIcon, BuySmartLogoOrangeStacked } from "gx-npm-icons";
import ActiveViewers from "./components/active-viewers.component";
import { colorPalette } from "gx-npm-common-styles";
import { GCOM_3695_ctaButtonColorUpdate } from "../../lib/feature-flags";

const useStyles = makeStyles(() => styles);

type BrandingHeaderProps = {
  activeViewers?: Array<string>;
  backBtnText?: string;
  backURL?: string;
  initName?: string;
  isLoading?: boolean;
};

const BrandingHeader = ({
  activeViewers = [],
  backBtnText = "",
  backURL = "",
  initName = "",
  isLoading = true,
}: BrandingHeaderProps) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = () => {
    singleSpa.navigateToUrl(backURL);
  };

  return (
    <div className={classes.brandHeader}>
      <div className={classes.backButton}>
        <Button rootClassName="btn-tertiary" onClick={handleClick}>
          <ArrowBackIcon {...(isFFGCOM3695 && { fillPath: colorPalette.interactions.defaultCta.hex })} />
          {t("Back to ")}
          {backBtnText}
        </Button>
        <div className={classes.backButtonDivider} />
        {isLoading ? (
          <Loader rootClassName={classes.brandingHeaderNameLoader} />
        ) : (
          <p>{initName ? initName : t("Untitled evaluation")}</p>
        )}
        <ActiveViewers viewerList={activeViewers} />
      </div>
      <div className={classes.brandLogo}>
        <BuySmartLogoOrangeStacked />
      </div>
    </div>
  );
};

export default BrandingHeader;
